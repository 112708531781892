import React, { Component } from 'react';
import './HomePage.css';
import house from '../Resources/house1.svg';
import houseFlipped from '../Resources/house1flipped.svg';
import arrow from '../Resources/arrow.svg';
import house2 from '../Resources/house2.svg';
import phoneButtonImage from '../Resources/phone.svg';
import Footer from '../Components/Footer';

class HomePage extends Component {
  constructor() {
    super();

    this.googleReportConversion = this.googleReportConversion.bind(this);
  }

  googleReportConversion(url) {
    window.gtag_report_call_conversion();
  }

  render() {
    return (
      <div className="homePage">
          <header>
              <div className="indexTitleDiv">
                  <h1 className="eoLTitle">
                      End of Lease
                  </h1>
                  <h1 className="ccbTitle">
                      Carpet Cleaning Bendigo
                  </h1>
              </div>
          </header>
          <a onClick={this.googleReportConversion} href="tel:0419337466" className="button" width="100%">
            <img src={phoneButtonImage} alt=""/>
          </a>
          <div className="houseImages">
              <div className="row">
                  <div className="columnLarge">
                      <img src={house} alt="" width="100%"/>
                  </div>
                  <div className="columnSmall">
                      <img src={arrow} alt="" width="100%"/>
                  </div>
                  <div className="columnLarge">
                      <img src={houseFlipped} alt="" width="100%"/>
                  </div>
              </div>
          </div>
          <div className="phNum">
              0419 337 466
          </div>
          <div className="bookOnlineLink" >
            <a href="https://endofleaseccb.com.au/book">Book Online</a>
          </div>
          <div className="stepsExtra">
              Receipt Supplied!
          </div>
          <div className="priceDiv">
              <aside>
                  <table className="priceTable">
                      <tbody>
                          <tr>
                              <td>
                                  1-2 Room(s)
                              </td>
                              <td>
                                    $140
                              </td>
                          </tr>
                          <tr>
                              <td>
                                  3 Rooms
                              </td>
                              <td>
                                    $165
                              </td>
                          </tr>
                          <tr>
                              <td>
                                  4 Rooms
                              </td>
                              <td>
                                    $190
                              </td>
                          </tr>
                          <tr>
                              <td>
                                  5 Rooms
                              </td>
                              <td>
                                    $215
                              </td>
                          </tr>
                          <tr>
                              <td>
                                  6 Rooms
                              </td>
                              <td>
                                    $240
                              </td>
                          </tr>
                          <tr>
                              <td colSpan="2">
                                Add $65 For Stairs
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </aside>
          </div>
          <div className="stepsDiv">
              <table className="stepsTable">
                  <tbody><tr>
                      <td className="cellpadding" align="right">
                          Step 1
                      </td>
                      <td align="left">
                          How many rooms?
                      </td>
                  </tr>
                  <tr>
                      <td className="cellpadding" align="right">
                          Note:
                      </td>
                      <td align="left">
                      Do not count hallways or
                      </td>
                  </tr>
                  <tr>
                      <td></td>
                      <td align="left">
                      wardrobes. They're free!
                      </td>
                  </tr>
                  <tr>
                      <td className="cellpadding" align="right">
                          Step 2
                      </td>
                      <td align="left">
                          Ring to confirm quote
                      </td>
                  </tr>
                  <tr>
                      <td></td>
                      <td align="left">
                          and details.
                      </td>
                  </tr>
              </tbody>
            </table>
          </div>
          <div className="space"/>
          <div className="stepsExtra">
              Please Leave Power ON
          </div>
          <br/>
          <div className="or">
              OR...
          </div>
          <div className="residential">
              Residential
          </div>
          <div className="residentialHouse">
              <img src={house2} width="100%" alt=""/>
          </div>
          {/* <div className="maybe">
              Maybe...
          </div>
          <div className="commercial">
              Commercial
          </div>
          <div className="esb">
              <img src={esb} width="100%" alt=""/>
          </div> */}
          <Footer/>
          <div className="bottomSpace"/>
      </div>
    );
  }
}

export default HomePage;
