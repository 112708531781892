/**
 * Created by david on 23/01/2017.
 */
import logo from '../Resources/house1.svg';
import React, { Component } from 'react';
import './NavBar.css'

class NavBar extends Component {
    render() {
        let tabs = [];
        for (let i = 0; i < this.props.tabData.length; i++) {
            tabs.push(<div className="navBarTab" key={this.props.tabData[i].title}
                           onClick={this.props.tabData[i].action}>{this.props.tabData[i].title}</div>)
            if (i !== (this.props.tabData.length - 1)) {
                tabs.push(<div className="navBarSpacer" key={"spacer" + i}/>);
            }
        }

        return (
        <div className="navBarContainer">
            <img src={logo} className="navBarLogo" alt="" onClick={this.props.mainAction}/>
            <div className="navBarTitle">
                <div className="navBarTitleTopLine" onClick={this.props.mainAction}>{this.props.titleTop}</div>
                <div className="navBarTitleBottomLine" onClick={this.props.mainAction}>{this.props.titleBottom}</div>
            </div>
            <div className="navBarTabContainer"/>
            {tabs}
        </div>
        );
    }
}

export default NavBar;
